import React from 'react'
import PropTypes from 'prop-types'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import TripleText from '@components/tripleText'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import YouTube from '@objects/youtube'
import PostStream from '@components/posts/stream'

export const frontmatter = {
  breadcrumbs: [
    {
      label: 'navigation.press',
      link: 'press.path',
    },
  ],
}

const useStyle = makeStyles((theme) => ({
  intro: {
    margin: theme.spacing(10, 0, 15, 0),
  },
  youtube: {
    marginBottom: theme.spacing(5),
  },
  tripleHeadline: {
    marginBottom: theme.spacing(8),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 'auto', 15, 'auto'),
      maxWidth: '50%',
    },
  },
  tripleText: {
    marginBottom: theme.spacing(15),
  },
  sublinewrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  flag: {
    background: theme.palette.red.main,
    color: theme.palette.text.invert,
    borderRadius: theme.spacing(5),
    fontWeight: 700,
    padding: theme.spacing(1, 6),
    whiteSpace: 'nowrap',
  },
}))

function RootIndex({ pageContext }) {
  const theme = useTheme()
  const classes = useStyle()
  const { getStaticFieldValue, getStaticFieldLink, getStaticFieldMedia } =
    useStaticModule(pageContext.modules)

  const intro = {
    headline: getStaticFieldValue(
      'livestream.intro',
      `livestream.intro.headline`
    ),
    copy: getStaticFieldValue('livestream.intro', 'livestream.intro.copy'),
    button: getStaticFieldValue('livestream.intro', 'livestream.intro.button'),
  }

  const youtube = {
    id: getStaticFieldLink('livestream.youtube', 'livestream.youtube.id'),
    title: getStaticFieldValue(
      'livestream.youtube',
      'livestream.youtube.title'
    ),
    flag: getStaticFieldValue('livestream.youtube', 'livestream.youtube.flag'),
    thumbnail: getStaticFieldMedia(
      'livestream.youtube',
      'livestream.youtube.thumbnail'
    ),
  }

  const tripleTextHeadline = getStaticFieldValue(
    'livestream.tripletext',
    `livestream.tripletext.headline`
  )

  const tripleText = ['1', '2', '3'].map((count) => {
    return {
      headline: getStaticFieldValue(
        'livestream.tripletext',
        `livestream.tripletext.${count}.headline`
      ),
      copy: getStaticFieldValue(
        'livestream.tripletext',
        `livestream.tripletext.${count}.copy`
      ),
    }
  })

  return (
    <>
      <Container
      ariaLabel="arialabel.auftaktlivestream"
      >
        <Grid container className={classes.intro}>
          <Grid item xs={12} lg={8}>
            <Headline level={3}>{intro.headline}</Headline>
            <Copy>{intro.copy}</Copy>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            container
            justify="flex-end"
            alignItems="center"
          >
            <Button type="secondary" color="outline" link="#dialog">
              {intro.button}
            </Button>
          </Grid>
        </Grid>
        <YouTube
          className={classes.youtube}
          ytid={youtube.id}
          thumbnail={youtube.thumbnail?.fluid}
          alt={youtube.thumbnail?.description}
        />
        <div className={classes.sublinewrapper}>
          <Headline level={4} className={classes.ytTitle}>
            {youtube.title}
          </Headline>
          {youtube.flag && <div className={classes.flag}>{youtube.flag}</div>}
        </div>
      </Container>
      <Container
        background={theme.palette.background.grey}
        padded
        type="nomargin"
        id="dialog"
      >
        <Grid container className={classes.intro} justify="center">
          <Grid item xs={12}>
            <Headline level={2} className={classes.tripleHeadline}>
              {tripleTextHeadline}
            </Headline>
            <TripleText
              className={classes.tripleText}
              items={tripleText}
              center
            />
          </Grid>
          <Grid item xs={12} md={10} lg={8}>
            <PostStream limit={20} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default RootIndex
